//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'filmCollectionItem',
  props: ['resource', 'index', 'cover', 'link'],
}
