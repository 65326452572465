//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'modelDummy',
  props: ['type', 'ratio', 'orientation', 'count', 'getLoadState', 'rounded', 'relatedItem'],
}
