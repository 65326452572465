//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
export default {
  name: 'showItem',
  mixins: [ImageSrcsetMixin],
  props: ['resource', 'index', 'minimal', 'ratio', 'type', 'thumb'],
  data() {
    return {
      hover: false,
      line_complete: false
    };
  },
  methods: {
    taphandler() {
      this.$router.push('/tv-shows/' + this.resource.slug);
    },
    endHandler(){
      if (!this.line_complete) {
        this.cancelAllPreviews();
      }
    },
    hoverHandler(todo) {
      if (this.resource.clips && this.resource.clips.data.length && this.resource.clips.data[0]) {
        this.hover = todo; 
      }
    },
    touchHandler() {
      var self = this;
      this.cancelAllPreviews();
      if (this.resource.clips && this.resource.clips.data[0]) {
        this.startPreview();
      }
    },
    startPreview(){
      var self = this;
      this.hover = true;
      setTimeout(function() {
        var element = document.getElementById('video_' + self.resource.id);
        if (element) {
          element.play();
          element.classList.add('active');
          self.line_complete = true;
        }
      }, 500);
    },
    cancelAllPreviews(){
      var self = this;
      document.querySelectorAll('.preview').forEach(function(element) { 
        if (element && element.id != 'video_' + self.resource.id) {
          element.autoplay = false;
          element.load();
          element.classList.remove('active');
        }
      });
      document.querySelectorAll('.line_animation').forEach(function(element) {
        if (element && element.id != 'line_' + self.resource.id) {
          element.classList.remove('active');
        }
      });
      this.hover = false
      this.line_complete = false;
    },
  }
}
