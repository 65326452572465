//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import videoItem from '~/components/content/video/video.vue'
import dummyItem from '~/components/content/dummy/video_dummy.vue'
export default {
    async fetch() {
        await this.$store.dispatch('resourcesStore/getResources', this.queries.recommendedVideos)
    },
    components: {
        videoItem,
        dummyItem
    },
    computed: {
        ...mapState({
            queries: ({ resourcesStore: {queries} }) => queries,
            recommendedVideos: ({ resourcesStore: {recommendedVideos} }) => recommendedVideos,
            loading: ({ resourcesStore: {loading} }) => loading
        }),
    },
}
