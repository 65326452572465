//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import showItem from '~/components/content/show/show.vue';
import dummyItem from '~/components/content/dummy/video_dummy.vue';
export default {
  async fetch() {
    await this.$store.dispatch('resourcesStore/getResources', this.queries.shows)
  },
  components: {
    showItem,
    dummyItem,
  },
  computed: {
    ...mapState({
      queries: ({ resourcesStore: {queries} }) => queries,
      shows: ({ resourcesStore: {shows} }) => shows,
      loading: ({ resourcesStore: {loading} }) => loading
    }),
  },
};
