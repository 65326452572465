//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      banner: ({ bannerStore: { banner } }) => banner
    }),
  },
};
