//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  async fetch() {
    let query = {
      include: 'images:types(popunder),products',
      page: 1,
      count: 50,
      sort: {
        published_at: 'ASC'
      },
      filters: {
        status: 'published',
      }
    }
    await this.$store.dispatch('popunderStore/getPopunder', query);
  },
  computed: {
    ...mapState({
      popunder: ({ popunderStore: {popunder} }) => popunder,
    }),
  },
  data() {
    return {
      upsale: true,
    }
  },
  mounted() {
    var upsale = false;
    if (this.popunder && this.popunder.fields && this.popunder.fields.utm_campaign) {
      var upsale = this.getCookie(this.popunder.fields.utm_campaign);
    }
    if (upsale) {
      this.upsale = false;
    }
  },
  methods: {
    dontShowUpsale: function(){
      let cname = this.popunder.fields.utm_campaign;
      let cvalue = 'hide';
      let exdays = 1;
      this.storeCookie(cname, cvalue, exdays);
    }
  }
}
