//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userChecks from '~/mixins/userChecks';
import VideoOfTheWeek from '~/components/home/VideoOfTheWeek.vue'
import recommendedVideos from '~/components/home/recommended_videos.vue'
import models from '~/components/home/populair_models.vue'
import cams from '~/components/home/live_cams.vue'
import shows from '~/components/home/new_shows.vue'
import film_collections from '~/components/home/film_collections.vue'
import epg from '~/components/home/epg_now.vue'
import topVideos from '~/components/home/top_videos.vue'
import VideoForYou from '~/components/home/VideoForYou.vue'

import banner from '~/components/banner.vue'
import popunder from '~/components/home/popunder.vue'

export default {
  mixins: [userChecks],
  components: {
    VideoOfTheWeek,
    recommendedVideos,
    models,
    cams,
    shows,
    film_collections,
    epg,
    topVideos,
    VideoForYou,
    banner,
    popunder
  },
  async fetch() {
    let query = { 
      zone: 'home',
      include: 'images',
    }
    await this.$store.dispatch('bannerStore/getBanner', query)
  },
  head() {
    return {
      title: process.env.APP_TITLE + ' | ' + this.$seo.home.title,
      meta: [
        { hid: 'title', name: 'title', content: process.env.APP_TITLE + ' | ' + this.$seo.home.title },
        { hid: 'description', name: 'description', content: this.$seo.home.description },
        { hid: 'og:url', name: 'og:url', content: process.env.FE_URL },
        { hid: 'og:title', name: 'og:title', content: process.env.APP_TITLE + ' | ' + this.$seo.home.title },
        { hid: 'og:description', name: 'og:description', content: this.$seo.home.description },
        { hid: 'keywords', name: 'keywords', content: this.$seo.home.keywords },
        ]
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.confirmcode) {
      next(vm => {
        vm.confirmUser(to.query.confirmcode);
      })
    } else if (to.query.resetcode) {
      next(vm => {
        vm.resetPswd(to.query.resetcode);
      })
    } else if (to.query.logincode) {
      next(vm => {
        vm.loginAsUser(to.query.logincode);
      })
    } else if (to.query.message && to.query.message === 'expired') {
      next(vm => {
        var notificationObj = {
          type: 'info',
          message: 'Je bent uitgelogd omdat je sessie is verlopen. Klik op deze melding om opnieuw in te loggen.',
          href: '/account/inloggen'
        };
        vm.$store.commit('notificationStore/setNotification', notificationObj);
      })
    } else {
      next();
    }
  },
  methods: {
    async confirmUser(confirmCode) {
      var self = this;
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
      let data = {
        confirmation_code: confirmCode,
      };
      await this.$axios.post(this.$config.apiUrl + '/api/sc/user-confirm', data, {
      }).then(function (response) {
        return self.login(response.data.authorisation.token, 'confirm');
      }).catch((error) => {
        var notificationObj = [];
        notificationObj.type = 'error';
        notificationObj.message = 'Er is iets mis gegaan. Je account is niet geactiveerd. Klik op deze melding om contact op te nemen.'
        notificationObj.href = '/info/contact';
        self.$store.commit('notificationStore/setNotification', notificationObj);
      })
    },
    async resetPswd(confirmCode) {
      var self = this;
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
      this.$router.push({
        path: '/account/wachtwoord-herstellen',
        query: { confirmation_code: confirmCode }
      })
    },
    async loginAsUser(confirmCode) {
      var self = this;
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
      let data = {
        confirmation_code: confirmCode,
      };
      await this.$axios.post(this.$config.apiUrl + '/api/sc/login-as-user', data, {
      }).then(function (response) {
        self.$auth.setUserToken(response.data.authorisation.token, response.data.authorisation.token).then(function () {
          self.$router.push({
            path: '/account/gegevens-voorkeuren'
          })
          var notificationObj = [];
          notificationObj.type = 'info';
          notificationObj.message = 'Je bent nu ingelogd als user: ' + response.data.user.email;
          return self.$store.commit('notificationStore/setNotification', notificationObj);
        })
      }).catch((error) => {
        var notificationObj = [];
        notificationObj.type = 'error';
        notificationObj.message = 'Er is iets mis gegaan. Je account is niet geactiveerd. Klik op deze melding om contact op te nemen.'
        notificationObj.href = '/info/contact';
        self.$store.commit('notificationStore/setNotification', notificationObj);
      })
    },      
    login(token, todo) {
      var self = this;
      var refreshToken = token;
      if (todo == 'resetpswd') {
        this.$auth.setUserToken(token, refreshToken).then(function () {
          self.$router.push({
            path: '/account/gegevens-voorkeuren?resetpswd'
          })
          var notificationObj = [];
          notificationObj.type = 'info';
          notificationObj.message = 'We hebben je wachtwoord gereset. Stel hierboven een nieuw wachtwoord in zodat je daar voortaan mee kunt inloggen.';
          self.$store.commit('notificationStore/setNotification', notificationObj);
        })
      } else {
        this.$auth.setUserToken(token, refreshToken).then(function () {
          var notificationObj = [];
          notificationObj.type = 'succes';
          notificationObj.message = 'Jouw registratie was een succes. We hebben je meteen ingelogd. Welkom bij ' + self.$config.appTitle + '!';
          self.$store.commit('notificationStore/setNotification', notificationObj);
        })
      }
    }
  }
}
