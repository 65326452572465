//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import modeltem from '~/components/content/model/model.vue';
import dummyItem from '~/components/content/dummy/model_dummy.vue';

export default {
  async fetch() {
    await this.$store.dispatch('resourcesStore/getResources', this.queries.models);
  },
  components: {
    modeltem,
    dummyItem,
  },
  computed: {
    ...mapState({
      queries: ({ resourcesStore: {queries} }) => queries,
      models: ({ resourcesStore: {models} }) => models,
      loading: ({ resourcesStore: { loading } }) => loading
    }),
  },
};
