//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { mapState } from 'vuex'
import collectionItem from '~/components/content/film_collection/collection.vue'
import dummyItem from '~/components/content/dummy/video_dummy.vue'
export default {
  async fetch() {
    await this.$store.dispatch('resourcesStore/getResources', this.queries.collections)
  },
  components: {
    collectionItem,
    dummyItem
  },
  data() {
    return {
      swiper: null
    };
  },
  computed: {
    ...mapState({
      queries: ({ resourcesStore: {queries} }) => queries,
      film_collections: ({ resourcesStore: {film_collections} }) => film_collections,
      loading: ({ resourcesStore: {loading} }) => loading
    }),
  },
  mounted() {
    if (this.film_collections && this.film_collections.data) {
      this.$nextTick(() => {
        this.swiper = new Swiper('.swiper-container', {
          modules: [Navigation, Pagination],
          loop: this.film_collections && this.film_collections.data && this.film_collections.data.length > 1 ? true : false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      });
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
}
