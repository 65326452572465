//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'epgItem',
  props: ['epgData', 'index', 'minimal', 'ratio'],
  computed: {
    randomImg () {
      return Math.floor(Math.random() * 7);
    },
  },
  methods: {
    goTo(item) {
      if (item.resources && item.resources.type == 'video') {
        this.$router.push('/seksfilms/' + item.resources.slug);  
      }
      if (item.resources && item.resources.type == 'epsiode') {
        this.$router.push('/tv-shows/' + item.resources.show_slug + '?episode=' + item.resources.slug);  
      }
    }
  }
}
