//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import dummyItem from '~/components/content/dummy/video_dummy.vue';

export default {
  components: {
    dummyItem,
  },
  async fetch() {
    await this.$store.dispatch('resourcesStore/getResources', this.queries.topVideos)
  },
  computed: {
    ...mapState({
      queries: ({ resourcesStore: {queries} }) => queries,
      videos: ({ resourcesStore: {topVideos} }) => topVideos,
      loading: ({ resourcesStore: {loading} }) => loading
    }),
  },
};
