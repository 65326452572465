//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapState } from 'vuex'
    import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
    import videoplayer from "~/components/content/video/videoplayer";
    export default {
        mixins: [ImageSrcsetMixin],
        async fetch() {
            await this.$store.dispatch('resourcesStore/getResources', this.queries.videoForYou)
        },
        components: {
          dummyItem: () => import('~/components/content/dummy/video_dummy.vue'),
          videoHighlight: () =>
          import('~/components/content/video/videoHighlight.vue'),
      },
      computed: {
        ...mapState({
            queries: ({ resourcesStore: {queries} }) => queries,
            videoForYou: ({ resourcesStore: {videoForYou} }) => videoForYou,
            loading: ({ resourcesStore: {loading} }) => loading,
            playerState: ({ mxmStore: {playerState} }) => playerState,
            playerOptions: ({ mxmStore: {playerOptions} }) => playerOptions,
            stream: ({ mxmStore: {stream} }) => stream
        }),
    },
    data() { 
        return {
          hover: false,
          line_complete: false,
          trailer: false,
          videoOptions: {
            autoplay: false,
            poster: false,
        },
    }
},
methods: {
    taphandler() {
        this.$router.push('/seksfilms/' + this.videoForYou.data[0].slug);
    },
    endHandler(){
      if (!this.line_complete) {
        this.cancelAllPreviews();
    }
},
hoverHandler(todo) {
  if (this.videoForYou.data[0].clips && this.videoForYou.data[0].clips.data[0]) {
    this.hover = todo; 
}
},
touchHandler() {
  var self = this;
  this.cancelAllPreviews();
  if (this.videoForYou.data[0].clips && this.videoForYou.data[0].clips.data[0]) {
    this.startPreview();
}
},
startPreview(){
  var self = this;
  this.hover = true;
  setTimeout(function() {
    var element = document.getElementById('video_' + self.videoForYou.data[0].id);
    if (element) {
      element.play();
      element.classList.add('active');
      self.line_complete = true;
  }
}, 500);
},
cancelAllPreviews(){
  var self = this;
  document.querySelectorAll('.preview').forEach(function(element) { 
    if (element && element.id != 'video_' + self.videoForYou.data[0].id) {
      element.autoplay = false;
      element.load();
      element.classList.remove('active');
  }
});
  document.querySelectorAll('.line_animation').forEach(function(element) {
    if (element && element.id != 'line_' + self.videoForYou.data[0].id) {
      element.classList.remove('active');
  }
});
  this.hover = false
  this.line_complete = false;
},
loadTrailer(videoId) {
    var dataObj = [];
    dataObj.video_id = videoId;
    dataObj.resource_id = this.videoForYou.data[0].id;
    dataObj.frontend_id = 5;
    dataObj.type = 'trailer';
    dataObj.location = 'vfy';
    this.$store.dispatch('mxmStore/getFreeStream', dataObj)
}
},
watch: {
  stream: function() {
    if (this.stream.location != 'vfy') {
        this.trailer = false;
    }
    if (this.stream.location == 'vfy' && this.stream.sources && this.stream.sources.streams) {
        this.trailer = true;
        if (this.videoForYou.data[0].images.data.cover_thumb) {
            var thumb = this.$config.cdn + this.videoForYou.data[0].images.data.cover_thumb[0].path
        } else {
            var thumb = this.$config.cdn + this.$config.errorimg;
        }
        let playerOptions = {
            autoplay: true,
            poster: thumb,
            sources: [{
                src: this.stream.sources.streams.mpd.url,
                type: this.stream.sources.streams.mpd.mimetype
            }]
        }
        this.$store.commit('mxmStore/updatePlayerOptions', playerOptions);
        this.$store.commit('mxmStore/setPlayerType', this.stream.type);
    }
}
}
}
