//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapState } from 'vuex'
    import epgItem from '~/components/content/epg/epg.vue'
    import dummyItem from '~/components/content/dummy/epg_dummy.vue'

    export default {
      async fetch() {
        await this.$store.dispatch('epgStore/getEpg', this.queries.epgNow)
    },
    components: {
        epgItem,
        dummyItem
    },
    data() { 
        return {

        };
    },
    computed: {
        ...mapState({
            queries: ({ epgStore: {queries} }) => queries,
            epgNow: ({ epgStore: {epgNow} }) => epgNow,
            loading: ({ epgStore: {loading} }) => loading
        }),
    },
}
