//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
export default {
  name: 'modelItem',
  mixins: [ImageSrcsetMixin],
  props: ['resource', 'index', 'rounded', 'ratio', 'type', 'thumb'],
  methods: {
    favorite(resource) {
      this.$emit('toggleFavorite', resource)
    }
  }
}
