//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRentDate } from '@/helpers/filters.js';
import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
export default {
  name: 'videoItem',
  mixins: [ImageSrcsetMixin],
  props: ['resource', 'index', 'ratio', 'type', 'id', 'activedate', 'cover'],
  methods: {
    formatRentDate,
    favorite(resource) {
      this.$emit('toggleFavorite', resource);
    },
  },
};
